import { get, writable, type Readable } from 'svelte/store'
import authState, { type SignInState } from './auth-state'

export type ModalType =
	| 'share'
	| 'login'
	| 'signup'
	| 'confirm-email'
	| 'publish-trip'
	| 'resend-confirmation'
	| 'palette'
	| 'custom-icon'
	| 'highlight-menu'
	| 'featured-images-menu'
	| 'add-coupon'
	| 'confirm-delete-trip'
	| 'confirm-delete-block'
	| 'confirm-unpublish-trip'
	| 'edit-description'
	| 'confirm-expire-coupon'
	| 'confirm-download-trip'
	| 'filter'
	| 'highlight-order'
	| 'block-order'
	| null

// Define a type without the `null` value for keys
export type ExtraTypeMapKey = Exclude<ModalType, null>

export type ExtraTypeMap = Record<ExtraTypeMapKey, unknown> & {
	'highlight-order': Record<string, never>
	'block-order': Record<string, never>
	signup: { intent: SignInState; callback?: AuthCallback }
	'confirm-delete-block': {
		blockId: string
		blockType: string
		blockTitle?: string
	}
	'edit-description': {
		description: string
	}
	'confirm-expire-coupon': {
		couponId: string
	}
}

export type AuthCallback = (err: Error | null) => void

const store = writable<ModalType>(null)
const extraStore = writable<Partial<ExtraTypeMap> | null>(null)

const open = <T extends ExtraTypeMap, K extends keyof ExtraTypeMap>(
	type: K,
	extra?: T[K] & { callback?: AuthCallback },
) => {
	store.set(type)
	if (extra) {
		extraStore.set({ [type]: extra } as Partial<ExtraTypeMap>)
		if (typeof extra.callback === 'function') {
			authState.addAuthCallback(extra.callback)
		}
	} else {
		extraStore.set({ [type]: null })
	}
}

const close = () => {
	store.set(null)
}

function getExtra<T extends ExtraTypeMap, K extends keyof ExtraTypeMap>(modalType: K): T[K] | null {
	const storeValue = get(extraStore)
	if (storeValue) {
		return storeValue[modalType] as T[K]
	}
	return null
}

export { extraStore as extra }

const modal: Readable<ModalType> & {
	open: typeof open
	close: typeof close
	extra: typeof getExtra
} = {
	subscribe: store.subscribe,
	open,
	close,
	extra: getExtra,
}

export default modal
